<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">

              <div class="col-6">
                <h4>
                  <router-link :to="{name:'Role'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Roles & Permissions
                </h4>
              </div>
              <div class="col-6 text-right">
                <button class="btn btn-primary" @click="rebootPermission" :loading="loading"><i class="fas fa-sync"></i>
                  Reboot Permission
                </button>
              </div>
              <div class="col-6 text-right">
                <!-- <router-link :to="{name:'user-new'}" academic-class="btn btn-primary ml-2">

                  Add Role
                </router-link> -->
              </div>
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  <tr v-for="(role, index) of roles" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span v-if="role.name && checkIsAccessible('role','list')"> <a href="#"
                                                                                      @click="managePermission(role.id)">{{
                        role.name
                      }} </a> </span>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <!-- <span academic-class="cursor-pointer ml-2" @click="deleteUser(slider.id)">
                      <i academic-class="fas fa-trash"></i>
                      </span>-->
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('role', 'manage-permission')">
                            <a href="#" class="navi-link"
                               @click="managePermission(role.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-cog "></i>
                                                            </span>
                              <span class="navi-text">  Manage Permission</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('role', 'manage-custom-permission')">
                            <a href="#" class="navi-link"
                               @click="manageCustomPermission(role.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-cog "></i>
                                                            </span>
                              <span class="navi-text">  Manage Custom Permission</span>
                            </a>
                          </b-dropdown-text>


                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="col-12 text-right">
                  <!-- <b-pagination
                    @input="getUsers"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                  ></b-pagination>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import RoleService from "@/services/user/role/RoleService";
import RoleCustomPermission from "@/services/user/role/RoleCustomPermission";

const userService = new UserService();
const roleService = new RoleService();
const customPermission = new RoleCustomPermission();

export default {
  name: "users",
  components: {},
  data() {
    return {
      roles: [],
      user: {},
      total: null,
      perPage: null,
      loading: false
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    userRoles() {
      userService.userRoles(this.user_id).then(response => {
        this.user_roles = response.data;
        this.selected_roles = [];
        this.user_roles.forEach(element => {
          this.selected_roles.push(element);
        });
        // alert(this.length)
      });
    },
    getUser() {
      userService.show(this.user_id).then(response => {
        this.user = response.data.user;
      });
    },
    getRoles() {
      roleService.all().then(response => {
        this.roles = response.data.data;
      });
    },
    showModal(id = null) {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = [];
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    managePermission(id) {
      this.$router.push({"name": "role-permission", params: {id: id}})
    }, manageCustomPermission(id) {
      this.$router.push({"name": "custom-permission", params: {id: id}})
    },
    deleteUserRole(role) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .removeRole(this.user_id, {roles: [role]})
                .then(response => {
                  this.$snotify.success("Deleted successfully");

                  this.userRoles();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    assignRole() {
      userService
          .assignRole(this.user_id, {roles: this.selected_roles})
          .then(response => {
            this.user_roles = [];
            this.hideModal();
            this.userRoles();
          });
    },
    rebootPermission() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.loading = true
            customPermission.rebootPermission().then(response => {
              this.loading = false;
              this.$snotify.success("User role and permission successfully rebooted")
            }).catch(error => {
              this.loading = true;
            })
          }
        }
      });
    }
  }
};
</script>
